import { createDataActionHook, IActionInput, IActionContext } from '@msdyn365-commerce/core';
import { getCustomerLoyaltyCardsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

const afterCart = async (_inputs: IActionInput | IActionInput[], cart: Cart | Cart[], actionContext: IActionContext) => {
    // tslint:disable-next-line: no-console
    if (actionContext.requestContext.user.isAuthenticated) {
        try {
            const card = await getCustomerLoyaltyCardsAsync({ callerContext: actionContext, queryResultSettings: {} }, null);
            if (card && card.length > 0) {
                if (
                    cart &&
                    //@ts-ignore
                    cart.cart &&
                    //@ts-ignore
                    (!cart.cart.LoyaltyCardId || cart.cart.LoyaltyCardId !== card[0].CardNumber)
                ) {
                    cart = await updateAsync(
                        { callerContext: actionContext },
                        {
                            //@ts-ignore
                            Id: cart.cart.Id,
                            LoyaltyCardId: card[0].CardNumber
                        }
                    );
                    console.info('Loyalty card information updated on the cart');
                }
            } else {
                if (
                    cart &&
                    //@ts-ignore
                    cart.cart &&
                    //@ts-ignore
                    cart.cart.LoyaltyCardId
                ) {
                    cart = await updateAsync(
                        { callerContext: actionContext },
                        {
                            //@ts-ignore
                            Id: cart.cart.Id,
                            LoyaltyCardId: ''
                        }
                    );
                    console.info('Loyalty card information updated on the cart');
                }
            }
        } catch (error) {
            console.error('An error occurred while updating loyalty cards information on the cart');
        }
    }
};

createDataActionHook({
    actionId: '@msdyn365-commerce/global-state/get-cart-state-data',
    postHook: afterCart
});
